.InfoBlock{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .imgSource{
    // width: 100%;
    height: 9rem;
  }
  .title{
    font-family: Baskerville;
    font-size: .49rem;
    margin-bottom: .42rem;
  }
  .detailCon{
    padding-bottom: .59rem;
    .detail{
      font-family: YouYuan;
      font-size: .26rem;
      // height: .5rem;
      // line-height: .5rem;
      text-align: center;
    }
  }
}
.BlockWithLine{
  padding: 0 1.73rem .1rem;
  
  .mainCon{
    border-top: 1px solid #000;
    .title{
      font-size: .5rem;
      height: .51rem;
      line-height: .51rem;
      font-family: YouYuan;
      margin: .33rem 0 .1rem;
      text-align: left;
      text-align: center;
    }
    .detailCon{
      .detail{
        font-size: .22rem;
        font-family: YouYuan;
        line-height: .36rem;
        height: .36rem;
      }
    }
  }
}
.swiperCon{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 19.2rem;
  position: relative;
}
.swiperItem{
  width: 19.2rem;
  height: 9.69rem;
}
/* .swiper-pagination{
  bottom: 3.13rem !important;
} */
.swiper-pagination-bullet{
  background-color: #929292 !important;
}
.swiper-pagination-bullet-active{
  background-color: #FFFFFF !important;
}
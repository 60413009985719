.aboutUs{
  .CONTACT{
    font-size: 2.03rem;
    font-family: Felix-Titling-Regular;
  }
  .FROMBEIJING{
    font-size: .18rem;
    font-family: Centaur-Regular;
    margin-top: .65rem;
  }
  .totheWORLD{
    font-size: .21rem;
    font-family: LucidaBright-Italic;
    // margin-top: .62rem;
  }
  .wnCon{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: .65rem;
  }
  .guanyuwm{
    font-family: NSimSun;
    font-size: .72rem;
    border-bottom: 1px solid #ffffff00;
  }
  .guanyuwm:hover{
    border-bottom: 1px solid #000;
  }
  .font3{
    width: 60%;
    height: auto;
  }
  .text1{
    font-family: DengXian;
    font-size: .26rem;
    font-weight: 600;
    margin-bottom: .21rem;
  }
  .text2{
    font-size: .24rem;
    font-family: YouYuan;
    margin-bottom: .21rem;
  }
  .blackBlock{
    // margin-top: .79rem;
    background-color: #000;
    padding-top: 2.23rem;
    padding: 2.23rem .82rem 0 .78rem;
    .AHISTORY{
      font-family: Centaur-Regular;
      font-size: .18rem;
      color: #fff;
    }
    .ofSUCCESS{
      font-family: LucidaBright-Italic;
      font-size: .18rem;
      margin-bottom: 1.34rem;
      color: #fff;
    }
  }
}

.Digital{
  background-color: #EDEDED;
  .CONTACT{
    font-size: 2.03rem;
    font-family: Felix-Titling-Regular;
  }
  .FROMBEIJING{
    font-size: .18rem;
    font-family: Centaur-Regular;
    margin-top: .65rem;
  }
  .totheWORLD{
    font-size: .21rem;
    font-family: LucidaBright-Italic;
  }
  .lxwm{
    font-family: NSimSun;
    font-size: .72rem;
    margin-top: .65rem;
  }
  .Digitaltwin{
    font-size: 2.03rem;
    font-family: Felix-Titling-Regular;
    margin-top: .65rem;
  }
  .qhz{
    font-size: .59rem;
    color: #8E8E89;
    font-family: Fontquan;
  }
  .qz{
    color: #000;
  }
  .hoverStyle:hover{
    color: #000;
  }
  .weHave{
    font-size: .26rem;
    color: #8E8E8E;
    margin-top: .24rem;
    font-family: YouYuan;
  }
  .mainCon{
    padding: 0 .8rem;
  }
}
.videoCon{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width:100%;
  /* height:9.02rem; */
}
.playB{
  width:100%;
  /* height:9.02rem; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btnI{
  width: .52rem;
  height: .52rem;
}
.swiperCon2{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 19.2rem;
  .swiperItem{
    width: 19.2rem;
    height: 9.69rem;
  }
  .swiper-pagination{
    bottom: .13rem !important;
  }
  .swiper-pagination-bullet{
    background-color: #000 !important;
  }
  .swiper-pagination-bullet-active{
    background-color: #FFFFFF !important;
  }
}

.game{
  background-color: #EDEDED;
  .Enter_the{
    height: 2.34rem;
    line-height: 2.34rem;
    font-size: 2rem;
    font-family: Felix-Titling-Regular;
  }
  .MASTERS1{
    height: .2rem;
    line-height: .2rem;
    font-size: .18rem;
    margin-top: .67rem;
    font-family: Centaur-Regular;
  }
  .HUMMINGBIRD{
    height: .23rem;
    line-height: .23rem;
    font-size: .21rem;
    font-family: LucidaBright-Italic;
    margin-top: .01rem;
  }
  .Incarentertainment{
    height: 1.71rem;
    line-height: 1.71rem;
    font-size: 1.46rem;
    font-family: Felix-Titling-Regular;
    margin-top: .89rem;
  }
  .cnyl{
    height: .72rem;
    line-height: .72rem;
    font-size: .72rem;
    font-family: NSimSun;
    margin-top: 1.27rem;
    color: #8E8E8E;
  }
  .qhz{
    font-size: .59rem;
    color: #8E8E89;
    font-family: Fontquan;
  }
  .qz{
    color: #000;
  }
  .hoverStyle:hover{
    color: #000;
  }
  .weHave{
    font-size: .26rem;
    color: #8E8E8E;
    margin-top: .24rem;
    font-family: YouYuan;
    margin-bottom: .24rem;
  }
  .mainCon{
    // padding: 0 .8rem;
    .imgbig{
      height: 9rem;
      width: 17.6rem;
    }
  }
  
}
@font-face {
  font-family: YellowPeas-UltraLight;
  src: url('./fonts/YellowPeas-UltraLight-2.otf');
}
@font-face {
  font-family: YouYuan;
  src: url('./fonts/YouYuan.ttf');
}
@font-face {
  font-family: PalatinoLinotype-Italic;
  src: url('./fonts/Palatino-Linotype-Italic.ttf');
}
@font-face {
  font-family: LucidaBright-Italic;
  src: url('./fonts/9.Lucida-Bright-Italic.ttf');
}
@font-face {
  font-family: LucidaBright;
  src: url('./fonts/3.Lucida-Bright.ttf');
}
@font-face {
  font-family: Centaur-Regular;
  src: url('./fonts/2.MtCentaur.otf');
}
@font-face {
  font-family: Edwardian-Script-ITC-Regular;
  src: url('./fonts/4.Edwardian-Script-ITC.ttf');
}
@font-face {
  font-family: DengXian;
  src: url('./fonts/DengXian.TTF');
}
@font-face {
  font-family: Baskerville;
  src: url('./fonts/7.BaskervilleOldFace.ttf');
}
@font-face {
  font-family: Kunstler;
  src: url('./fonts//8.KunstlerScript.ttf');
}
@font-face {
  font-family: Centaur-Regular;
  src: url('./fonts/Centaur-Regular.otf');
}
@font-face {
  font-family: CenturyGothic;
  src: url('./fonts/CenturyGothic.TTF');
}
@font-face {
  font-family: NSimSun;
  src: url('./fonts/simsun.ttc');
}
@font-face {
  font-family: Fontquan;
  src: url('./fonts/Fontquan.ttf');
}
@font-face {
  font-family: Colonna;
  src: url('./fonts/10.Colonna-MT.ttf');
}
@font-face {
  font-family: Calibri-Regular;
  src: url('./fonts/12.Calibri-Light-Italic.ttf');
}
@font-face {
  font-family: Perpetua-Titling-MT-Light;
  src: url('./fonts/11.Perpetua-Titling-MT-Light.ttf');
}
@font-face {
  font-family: Book-Antiqua-Regular;
  src: url('./fonts/Book-Antiqua.ttf');
}
@font-face {
  font-family: Bookman-Old-Style-Regular;
  src: url('./fonts/Bookman-Old-Style.TTF');
}
@font-face {
  font-family: Felix-Titling-Regular;
  src: url('./fonts/Felix-Titling.ttf');
}
@font-face {
  font-family: Rawengulk-Ultralight;
  src: url('./fonts/Rawengulk-Ultralight.ttf');
}
@font-face {
  font-family: MF-DianHei;
  src: url('./fonts/MF-DianHei.ttf');
}
@font-face {
  font-family: DengXianNew;
  src: url('./fonts/DengXiannew.ttf');
}
@font-face {
  font-family: Mingliu;
  src: url('./fonts/Mingliu.ttc');
}
.headerCon1{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.12rem .80rem;
  height: 1.44rem;
  .logo{
    width: 0.73rem;
    height: 1.02rem;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .menu{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .menuItem1{
        color: rgb(142, 142, 142);
        /* width: 112rem; */
        height: 0.41rem;
        line-height: 0.41rem;
        font-size: 0.28rem;
        margin-left: 0.41rem;
        overflow: hidden;
        border-bottom: 0.02rem solid rgba(0,0,0,0);
        font-family: 'YouYuan';
      }
      .menuItem1:hover{
        color: #fff;
        border-bottom: 0.02rem solid rgb(255, 255, 255);
      }
      .menuItemActive1{
        color: #fff;
        /* width: 112rem; */
        height: 0.41rem;
        line-height: 0.41rem;
        font-size: 0.28rem;
        margin-left: 0.41rem;
        border-bottom: 0.02rem solid #fff;
        overflow: hidden;
        font-family: 'YouYuan';
      }
    }
    .more{
      width: 0.54rem;
      height: 0.41rem;
      margin-left: 0.92rem;
    }
  }
}




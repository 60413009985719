.indexStyle{
  .ESTABLISHED{
    font-size: 0.15rem;
    color: #000;
    margin-top: 1.25rem;
    font-family: 'Centaur-Regular';
  }
  .in2013{
    /* font-size: 'LucidaBright'; */
    color: #000;
    font-size: .18rem;
    .in{
      font-size: .21rem;
      font-weight: 600;
      font-family: 'Edwardian-Script-ITC-Regular';
      margin-right: .04rem;
    }
    .n2013{
      font-size: 'LucidaBright-Italic';
      color: #000;
      font-size: .18rem;
    }
  }
  .weare{
    height: .94rem;
    line-height: .94rem;
    font-size: .94rem;
    font-weight: 500;
    font-family: 'PalatinoLinotype-Italic';
    margin-top: .92rem;
  }
  .HUMMINGBIRD{
    font-size: 1.92rem;
    // font-weight: 700;
    font-family: 'YellowPeas-UltraLight';
    height: 1.92rem;
    line-height: 1.92rem;
    margin-top: -.5rem;
  }
  .fnhd{
    height: .5rem;
    line-height: .5rem;
    font-size: .34rem;
    font-family: 'YellowPeas-UltraLight';
    font-weight: 100;
  }
  .font111{
    margin-top: .78rem;
    height: 2.4rem;
  }
  .font121{
    height: .5rem;
  }
  .section1{
    margin-top: .78rem;
    .indexText1{
      // line-height: .21rem;
      font-size: .18rem;
      color: #000;
      font-family: DengXian;
      font-weight: 500;
    }
  }
  .section2{
    margin-top: .23rem;
    .indexText2{
      // line-height: .15rem;
      font-size: .15rem;
      font-family: DengXian;
      margin-bottom: .1rem;
    }
    .undelineCon{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: .1rem;
      .undeline{
        width: 2.79rem;
        height: 2px;
        background-color: #000;
      }
    }
    .text-d{
      margin-bottom: 4px !important;
    }
    
    .indexText3{
      // line-height: .18rem;
      font-size: .15rem;
      font-family: DengXian;
      color: #8D8D8D;
    }
  }
}



.line192{
  width: 1px;
  background-color: #000;
  height: 1.92rem;
}

.scaleImg{
  margin-top: 2.3rem;
  padding: .2rem;
  border: #fff 1px solid;
  animation:animate1 .4s linear;
  border-radius: 5px;
}
.scaleImg:hover{
  border: #000 1px solid;
  border-radius: 5px;
  animation:animate .4s linear
}
@keyframes animate {
  0%
  {
    border: #fff 1px solid;
  }
  100%
  {
    border: #000 1px solid;
  }
}
@keyframes animate1 {
  0%
  {
    border: #000 1px solid;
  }
  100%
  {
    border: #fff 1px solid;
  }
}
.font1{
  width: 6.44rem;
}
.section4{
  margin-top: .78rem;
}
.indexText4{
  font-size: 1rem;
  font-family: Baskerville;
}
.indexText4-1{
  font-size: 1.15rem;
  font-family: YellowPeas-UltraLight;
  margin-top: -0.6rem;
}
.indexText5-1{
  font-size: .18rem;
  font-family: DengXian;
  font-weight: 500;
}
.indexText5-2{
  font-size: .15rem;
  font-family: DengXian;
  margin-top: .11rem;
}
.SEEALLPROJECTS{
  font-family: CenturyGothic;
  font-size: .21rem;
  margin-bottom: .71rem;
  margin-top: .61rem;
}
.grayBlock{
  background-color: #BFBFBB;
  padding-top: .68rem;
  margin-top: -.33rem;
}
.indexBanner{
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
}
.MASTERING{
  font-size: .26rem;
  font-family: Centaur-Regular;
  margin: 0;
  display: block;
}
.theTECHNIQUES{
  height: 0.38rem;
  margin: 0;
}
.the{
  font-size: .38rem;
  font-family: Kunstler;
  display: inline;
}
.TECHNIQUES{
  font-size: .32rem;
  font-family: LucidaBright;
}
.ywfw{
  font-size: .32rem;
  font-family: LucidaBright;
  margin: 0;
}
.section6{

}
.indexText6{
  font-size: 1rem;
  color: #8E8E89;
  line-height: 1.1rem;
  height: 1.1rem;
  font-family: Fontquan;
}
.hoverStyle:hover{
  color: #000;
}
.section7{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.indexText7{
  width: 3.29rem;
  height: .7rem;
  border: 1px solid #787878;
  font-size: .25rem;
  line-height: .7rem;
  text-align: center;
  color: #787878;
}
.section8{
  margin-top: 1rem;
}
.indexText8-1{
  font-family: Baskerville;
  font-size: 1rem;
  height: 1rem;
  line-height: 1.75rem;
}
.indexText8-2{
  font-family: YellowPeas-UltraLight;
  font-size: 1.15rem;
  height: 1.15rem;
  line-height: 1.15rem;
}
.indexText8-3{
  font-family: NSimSun;
  font-size: .42rem;
}
.blackBlock{
  background-color: #000;
  padding-top: .5rem;
}
.COOPERATIVE{
  color: #fff;
  font-size: .48rem;
  font-family: LucidaBright-Italic;
  padding-bottom: .56rem;
}
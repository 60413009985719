.SmallBlock{
  height: 5.32rem;
  width: 7.22rem;
  border-bottom: 1px solid #707070;
  padding-bottom: .7rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  .text1{
    font-size: 2.07rem;
    font-family: Rawengulk-Ultralight;
  }
  .text2{
    font-size: .65rem;
    font-family: MF-DianHei;
  }
}
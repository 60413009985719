.CONTACTUS{
  background-color: #ededed;
  .CONTACT{
    font-size: 2.03rem;
    font-family: Felix-Titling-Regular;
  }
  .FROMBEIJING{
    font-size: .18rem;
    font-family: Centaur-Regular;
    margin-top: .65rem;
  }
  .totheWORLD{
    font-size: .21rem;
    font-family: LucidaBright-Italic;
    // margin-top: .62rem;
  }
  .CONTACT{
    font-size: 2.03rem;
    font-family: Felix-Titling-Regular;
    margin-top: .62rem;
  }
  .us{
    font-family: Felix-Titling-Regular;
    margin-top: .62rem;
    font-size: 2.03rem;
  }
  .lxwm{
    font-family: NSimSun;
    font-size: .72rem;
    margin-top: .85rem;
  }
  .WHEREWEARE{
    height: 1.7rem;
  }
  .address{
    font-family: CenturyGothic;
    font-size: .25rem;
    margin-top: .15rem;
    margin-bottom: .3rem;
  }
  .addressEnglish{
    font-family: CenturyGothic;
    font-size: .25rem;
    margin-bottom: .27rem;
  }
  .nameP1{
    font-size: .25rem;
    font-family: CenturyGothic;
    font-weight: 600;
    letter-spacing:5px
  }
  .nameP{
    font-size: .25rem;
    font-family: CenturyGothic;
    font-weight: 600;
    letter-spacing:2px
  }
  .erco{
    width: 2rem;
    height: 2rem;
    margin-top: .8rem;
  }
  .itemCon{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: .73rem;
    margin-bottom: 2.08rem;
    .item{
      // width:3.31rem;
      .itemtop{
        // width:3.29rem;
        padding: 0 .3rem;
        border: 1px solid #000;
        font-size: .25rem;
        text-align: center;
        height: .7rem;
        line-height: .7rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .itembottom{
        font-size: .24rem;
        font-family: DengXian;
        margin-top: .21rem;
      }
    }
  }
}